const GetFormattedDuration = (duration, t) => {
    const duration_days = Math.floor(duration / 1440);
    const duration_hours = Math.floor(duration / 60) % 24;
    const duration_minutes = duration % 60;
    let formated_duration = '';
    if (duration_days > 0) {
        formated_duration += `${duration_days} ${t('duration.days')}`;
    }
    if (duration_hours > 0) {
        formated_duration += `${duration_hours} ${t('duration.hours')}`;
    }
    if (duration_minutes > 0) {
        formated_duration += `${duration_minutes} ${t('duration.minutes')}`;
    }
    return formated_duration;
};

export default GetFormattedDuration;