import i18next from "i18next";
export default (config_code, accommodations, cars, flights, poi, transfers, manual_products, version, currency, currency_list, t, getTaxesPrice, assistance_cart) => {
    let inclusions = [];
    let non_inclusions = [];
    let flight_conditions = "Non annulable / Non remboursable";
    let terrestrial_conditions = [];
    if (config_code === "verdie"){
//        non_inclusions.push("Les repas (sauf mention contraire ci-dessus)");
//        non_inclusions.push("Les entrées sur les sites et dans les parcs");
//        non_inclusions.push("Les dépenses personnelles");
//        non_inclusions.push("Les assurances voyage")
        terrestrial_conditions.push('Plus de 44 jours avant le départ : 20% du montant du voyage');
        terrestrial_conditions.push('De 44 à 21 jours avant le départ : 40% du montant du voyage');
        terrestrial_conditions.push('De 20 à 8 jours avant le départ : 70% du montant du voyage');
        terrestrial_conditions.push('Moins de 8 jours avant le départ : 90% du montant du voyage');
        let has_cancel = flights.find((flight) => {
            return (!flight.is_optional && flight.is_displayed && (flight.booking_status === null || (flight.booking_process_state !== 'CANCEL' && flight.booking_status.status_booking !== 'CANCELLED')) && (flight.cancel_info !== '' || flight.cancel_condition_text !== null))
        });
        if (has_cancel !== undefined) {
            flight_conditions = "Annulable sous conditions"
        }
        let show_accommodations = false;
        accommodations.map((accommodation) => {
            if (accommodation.is_available && accommodation.is_displayed && (accommodation.booking_process_state === "NOTHING" || accommodation.booking_process_state === "BOOK" || accommodation.booking_process_state === "UPDATE")) {
                show_accommodations = true;            
            }
        });
        if (show_accommodations) {
            inclusions.push(t("quotation.accommodation"));
            non_inclusions.push(t("quotation.accommodation_taxe"));
        } else {
            non_inclusions.push(t("quotation.accommodation"));
        }
        
        let all_taxes_per_pax = 0;
        let show_flights = false;
        flights.map((flight) => {
            if (flight.is_available && flight.is_displayed && (flight.booking_process_state === "NOTHING" || flight.booking_process_state === "BOOK" || flight.booking_process_state === "UPDATE")) {
                show_flights = true;
                all_taxes_per_pax += getTaxesPrice(flight.taxes) / flight.passenger.total;
            }
        });
        if (version.flight_taxes !== null) {
            all_taxes_per_pax = parseFloat(version.flight_taxes);
        }

        if (show_flights) {
            inclusions.push(t("quotation.flights"));
            inclusions.push(t("quotation.airport_taxe_surchage") + " ( " + (all_taxes_per_pax.toFixed(2)).toString().replace(".", ",") + currency.symbol + "/pers)");
        } else {
//            non_inclusions.push(t("quotation.flights"));
//            non_inclusions.push("Les taxes aéroport et surcharge carburant");
        }

        let show_cars = false;
        cars.map((car) => {
            if (car.is_available && car.is_displayed && (car.booking_process_state === "NOTHING" || car.booking_process_state === "BOOK" || car.booking_process_state === "UPDATE")) {
                show_cars = true;
            }
        });
        if (show_cars) {
            inclusions.push(t("quotation.rental_car"));
            non_inclusions.push(t("quotation.fuel"));
//            non_inclusions.push("Les frais de stationnement et de péage (À noter que sur certaines grandes villes les parkings peuvent être chers)");
//            non_inclusions.push("Les options, éventuels frais de one way et assurances complémentaires à régler sur place si location de voiture");
        } else {
            non_inclusions.push(t("quotation.rental_car"));
        }

        let show_transfers = false;
        transfers.map((transfer) => {
            if (transfer.is_available && transfer.is_displayed && (transfer.booking_process_state === "NOTHING" || transfer.booking_process_state === "BOOK" || transfer.booking_process_state === "UPDATE")) {
                show_transfers = true;
            }
        });
        if (show_transfers) {
            inclusions.push(t("quotation.transfers"));
        } else {
//            non_inclusions.push("Les transferts");
        }

        let show_poi = false;
        poi.map((poi_product) => {
            if (poi_product.is_available && poi_product.is_displayed && (poi_product.booking_process_state === "NOTHING" || poi_product.booking_process_state === "BOOK" || poi_product.booking_process_state === "UPDATE")) {
                show_poi = true;
            }
        });
        if (show_poi) {
            inclusions.push(t("quotation.activities"));
        } else {
//            non_inclusions.push(t("quotation.activities"));
        }

        non_inclusions.push(t("quotation.all_not_included"));

    // }else if (config_code === "visiteurs"){
    } else if (config_code === 'cercledesvoyages') {
        non_inclusions.push('Les repas non mentionnés dans le devis');
        non_inclusions.push('Les dépenses à caractères personnel');
        non_inclusions.push('Les droits d\'entrées sur les sites (sauf inclus dans le programme)');
        non_inclusions.push('Les frais liés aux formalités d\'entrée dans le pays, sauf si inclus dans le devis');
        non_inclusions.push('Les assurances voyage  sauf si inclus dans le devis ou option choisie');
        non_inclusions.push('Les pourboires');
        non_inclusions.push('Les options non sélectionnées');
        let show_accommodations = false;
        let accommodation_resort_fees = [];
        accommodations.map((accommodation) => {
            if (accommodation.is_available && accommodation.is_displayed && !accommodation.is_optional && (accommodation.booking_process_state === "NOTHING" || accommodation.booking_process_state === "BOOK" || accommodation.booking_process_state === "UPDATE") && !accommodation.is_hidden_for_traveler && !accommodation.is_hidden_for_traveler_count_price) {
                show_accommodations = true;
                let resort_fees = 0;
                let resort_fees_currency = null;
                if (accommodation.rooms !== undefined) {
                    accommodation.rooms.map(room => {
                        if (!room.resort_fees_included && room.resort_fees !== null && room.resort_fees !== '0.00') {
                            resort_fees += parseFloat(room.resort_fees);
                            resort_fees_currency = currency_list.find(currency => currency.id === room.resort_fees_currency_id);
                        }
                    });
                    if (resort_fees > 0 && !!resort_fees_currency) {
                        accommodation_resort_fees.push(`  ${accommodation.hotel[0].name} : ${resort_fees}${resort_fees_currency.symbol}`)
                    } else if (!accommodation.resort_fees_included && !!accommodation.resort_fees_text) {
                        accommodation_resort_fees.push(`  ${accommodation.hotel[0].name} : Voir montant sur place`)
                    }
                }
            }
        });
        if (show_accommodations) {
            inclusions.push('Les hébergements mentionnés dans le devis');
            inclusions.push('Les petits déjeuners et repas mentionnés dans le devis');
            non_inclusions.push('Les éventuelles taxes de séjour');
            non_inclusions.push('Les éventuels Resort fees à payer sur place mentionnés dans le devis');
            non_inclusions = non_inclusions.concat(accommodation_resort_fees);
        } else {
            non_inclusions.push('Les hébergements');
        }
        let all_taxes_per_pax = 0;
        let show_flights = false;
        flights.map((flight) => {
            if (flight.is_available && flight.is_displayed && (flight.booking_process_state === "NOTHING" || flight.booking_process_state === "BOOK" || flight.booking_process_state === "UPDATE")) {
                show_flights = true;
                all_taxes_per_pax += getTaxesPrice(flight.taxes) / flight.passenger.total;
            }
        });
        if (version.flight_taxes !== null) {
            all_taxes_per_pax = parseFloat(version.flight_taxes);
        }

        if (show_flights) {
            inclusions.push('Les vols mentionnés dans le devis (taxes aéroport incluses)');
            // inclusions.push(t("quotation.airport_taxe_surchage") + " ( " + (all_taxes_per_pax.toFixed(2)).toString().replace(".", ",") + currency.symbol + "/pers)");
        } else {
            non_inclusions.push('Les vols');
//            non_inclusions.push("Les taxes aéroport et surcharge carburant");
        }

        let show_cars = false;
        cars.map((car) => {
            if (car.is_available && car.is_displayed && (car.booking_process_state === "NOTHING" || car.booking_process_state === "BOOK" || car.booking_process_state === "UPDATE")) {
                show_cars = true;
            }
        })
        if (show_cars) {
            inclusions.push('La location de voiture');
            const special_equipments = [];
            const onsite_fare = [];
            if (cars && cars.length) {
                cars.forEach(car => {
                    if (car.special_equipments?.length) {
                        const special_equipments_for_this_car =
                            car.special_equipments.map((eq) => `${eq.name} ${new Intl.NumberFormat(i18next.language, {
                                style: 'currency',
                                currency: eq.currency ?? 'EUR'
                            }).format(parseFloat(eq.price) ?? 0)}`)
                        if (special_equipments_for_this_car) special_equipments.push(special_equipments_for_this_car.join(' - '));
                    }

                    if (car.onsite_fare?.length) {
                        const onsite_fare_for_this_car =
                            car.onsite_fare.map((eq) => {
                                const current_currency = currency_list?.find(currency => currency.id == eq.currency);
                                return `${eq.description} ${new Intl.NumberFormat(i18next.language, {
                                    style: 'currency',
                                    currency: current_currency?.iso_code ?? 'EUR'
                                }).format(parseFloat(eq.price) ?? 0)}`
                            })
                        if (onsite_fare_for_this_car) onsite_fare.push(onsite_fare_for_this_car.join(' - '));
                    }
                });
            }
            non_inclusions.push(`Les frais d'abandon du véhicule et autres services ${special_equipments.length > 0 ? '(' + special_equipments.join(', ') + ')' : ''}`);
            non_inclusions.push(`Les frais à payer sur place ${onsite_fare.length > 0 ? '(' + onsite_fare.join(', ') + ')' : ''}`);
            non_inclusions.push("Le GPS (sauf mention contraire dans le devis)", "Les frais de parkings, carburant et de péages");
            // non_inclusions.push(t("quotation.fuel"));
//            non_inclusions.push("Les frais de stationnement et de péage (À noter que sur certaines grandes villes les parkings peuvent être chers)");
//            non_inclusions.push("Les options, éventuels frais de one way et assurances complémentaires à régler sur place si location de voiture");
//         }else{
//             non_inclusions.push('Les frais d\'abandon du véhicule (si mentionné dans le devis)');
//             non_inclusions.push('Le GPS (sauf mention contraire dans le devis)');
//             non_inclusions.push('Les frais de parkings, carburant et de péages');
        }

        let show_transfers = false;
        transfers.map((transfer) => {
            if (transfer.is_available && transfer.is_displayed && (transfer.booking_process_state === "NOTHING" || transfer.booking_process_state === "BOOK" || transfer.booking_process_state === "UPDATE")) {
                show_transfers = true;
            }
        })
        if (show_transfers) {
            inclusions.push('Les transferts mentionnés dans le devis');
        } else {
//            non_inclusions.push("Les transferts");
        }

        let show_poi = false;
        poi.map((poi_product) => {
            if (poi_product.is_available && poi_product.is_displayed && (poi_product.booking_process_state === "NOTHING" || poi_product.booking_process_state === "BOOK" || poi_product.booking_process_state === "UPDATE")) {
                show_poi = true;
            }
        })
        if (show_poi) {
            inclusions.push('Les activités mentionnées dans le devis');
        } else {
//            non_inclusions.push(t("quotation.activities"));
        }
        inclusions.push('Le carnet de voyage personnalisé');
        inclusions.push('Notre service conciergerie');
    } else if (config_code === 'touristforum') {
        let show_accommodations = false;
        let accommodation_resort_fees = [];
        accommodations.map((accommodation) => {
            if (accommodation.is_available && accommodation.is_displayed && !accommodation.is_optional && (accommodation.booking_process_state === "NOTHING" || accommodation.booking_process_state === "BOOK" || accommodation.booking_process_state === "UPDATE") && !accommodation.is_hidden_for_traveler && !accommodation.is_hidden_for_traveler_count_price) {
                show_accommodations = true;
                let resort_fees = 0;
                let resort_fees_currency = null;
                if (accommodation.rooms !== undefined) {
                    accommodation.rooms.map(room => {
                        if (!room.resort_fees_included && room.resort_fees !== null && room.resort_fees !== '0.00') {
                            resort_fees += parseFloat(room.resort_fees);
                            resort_fees_currency = currency_list.find(currency => currency.id === room.resort_fees_currency_id)
                        }
                    });
                    if (resort_fees > 0 && !!resort_fees_currency) {
                        accommodation_resort_fees.push(`  ${accommodation.hotel[0].name} : ${resort_fees}${resort_fees_currency.symbol}`)
                    } else if (!accommodation.resort_fees_included && !!accommodation.resort_fees_text) {
                        accommodation_resort_fees.push(`  ${accommodation.hotel[0].name} : Voir montant sur place`)
                    }
                }
            }
        });
        let show_transfers = false;
        transfers.map((transfer) => {
            if (transfer.is_available && transfer.is_displayed && (transfer.booking_process_state === "NOTHING" || transfer.booking_process_state === "BOOK" || transfer.booking_process_state === "UPDATE")) {
                show_transfers = true;
            }
        });
        let show_poi = false;
        poi.map((poi_product) => {
            if (poi_product.is_available && poi_product.is_displayed && (poi_product.booking_process_state === "NOTHING" || poi_product.booking_process_state === "BOOK" || poi_product.booking_process_state === "UPDATE")) {
                show_poi = true;
            }
        });
        let show_insurance = false;
        if (assistance_cart) {
            assistance_cart.map((assistance) => {
                if (assistance.product_type === 8 && assistance.is_available && assistance.is_displayed && (assistance.booking_process_state === "NOTHING" || assistance.booking_process_state === "BOOK" || assistance.booking_process_state === "UPDATE")) {
                    show_insurance = true;
                }
            });
        }
        if (show_accommodations) {
            inclusions.push('Alojamientos mencionados en la cotización.');
            inclusions.push('Desayunos y comidas mencionadas en la cotización.');
            non_inclusions.push('Cualquier tarifa del Resort a pagar en el sitio mencionada en la cotización.');
        } else {
            non_inclusions.push('Alojamientos');
        }
        let show_flights = false;
        flights.map((flight) => {
            if (flight.is_available && flight.is_displayed && (flight.booking_process_state === "NOTHING" || flight.booking_process_state === "BOOK" || flight.booking_process_state === "UPDATE")) {
                show_flights = true;
            }
        });

        if (show_flights) {
            inclusions.push('Vuelos mencionados en la cotización (tasas aeroportuarias incluidas)');
            non_inclusions.push('Reserva de asientos')
        } else {
            non_inclusions.push('Vuelos');
            non_inclusions.push('Impuestos de aeropuerto');
        }
        let show_cars = false;
        cars.map((car) => {
            if (car.is_available && car.is_displayed && (car.booking_process_state === "NOTHING" || car.booking_process_state === "BOOK" || car.booking_process_state === "UPDATE")) {
                show_cars = true;
            }
        })
        if (show_cars) {
            inclusions.push('Alquiler de coches');
            non_inclusions.push('Costos de abandono del vehículo (si se menciona en la cotización)');
            non_inclusions.push('GPS (a menos que se indique lo contrario en la cotización)');
            non_inclusions.push('Gastos de aparcamiento, combustible y peajes.');
        } else {
            non_inclusions.push('Alquiler de coches');
        }
        if (show_transfers) {
            inclusions.push('Transferencias mencionadas en la cotización.');
        } else {
            non_inclusions.push('Traslados al aeropuerto');
        }
        if (show_poi) {
            inclusions.push('Entradas, actividades y/o excursiones mencionadas en la cotización');
        } else {
            non_inclusions.push('Entradas, actividades y/o excursiones')
        }
        if (show_insurance) {
            inclusions.push('Seguro de viaje con asistencia médica y cobertura de cancelación');
        } else {
            non_inclusions.push('Seguro de viaje');
        }
        non_inclusions.push('Comidas, actividades y excursiones no mencionadas específicamente en el presupuesto como incluidas')
        non_inclusions.push('Gastos personales')
    } else if (config_code === 'tropicalementvotre') {
        inclusions.push("Les vols internationaux aller-retour en classe économique");
        inclusions.push("L'accueil et l'assistance de notre correspondant sur place");
        inclusions.push("Les hébergement(s), prestation(s) et repas mentionnés au programme");
        inclusions.push("Les transferts aéroport/hôtel aller-retour");
        inclusions.push("Les taxes aéroport");
        non_inclusions.push("Les dépenses à caractère personnel");
        non_inclusions.push("Les assurances facultatives (sauf mention contraire dans le devis)");
        non_inclusions.push("Les prestations non mentionnées au programme");
        non_inclusions.push("Les frais de visa ou de toute(s) autre(s) formalité(s) administrative(s)");
        non_inclusions.push("Les repas non mentionnés dans le devis");
        non_inclusions.push("Les éventuelles taxes à régler sur place (ex : taxe de séjour, resort fees) mentionnées dans le devis");
    } else if (config_code === 'continentsinsolites') {
        if (accommodations.length !== 0){
            inclusions.push("Le logement en catégorie sélectionnée dans les hôtels mentionnés");
            inclusions.push("Les repas tels que mentionnés dans le détail quotidien");
            non_inclusions.push("Les éventuelles taxes de séjour ou contributions locales précisées dans votre itinéraire");
        } else {
            non_inclusions.push("Les hébergements ");
        }

        if (flights.length !== 0){
            inclusions.push("Les vols internationaux et locaux tels que mentionnés dans votre programme ");
            inclusions.push("Les taxes d’aéroport liées à ces trajets");
        }else{
            non_inclusions.push("Les vols non inclus dans votre programme");
            non_inclusions.push("Les taxes d’aéroport liées à ces trajets");
        }

        if (cars.length !== 0){
            inclusions.push("La location de voiture");
            non_inclusions.push("Les éventuels suppléments liés à la restitution du véhicule, sauf mention spécifique dans votre programme");
            non_inclusions.push("Le système GPS, sauf mention spécifique dans votre programme ");
            non_inclusions.push("Les dépenses liées au stationnement et aux péages");
        }else{
            non_inclusions.push("La location de voiture");
        }
        inclusions.push("Les services inclus dans votre proposition de voyage");
        inclusions.push("Les découvertes et expériences telles que détaillées dans votre jour par jour");
        inclusions.push("Votre carnet de voyage personnalisé");
        inclusions.push("La préparation de votre voyage ainsi que l'assistance en français durant votre voyage, 7 jours sur 7");

        non_inclusions.push("Les services non mentionnés dans votre proposition de voyage");
        non_inclusions.push("Les repas et boissons non inclus dans le déroulé du voyage");
        non_inclusions.push("Les dépenses personnelles ainsi que les pourboires laissés à votre appréciation personnelle");
        non_inclusions.push("L’assurance annulation (sauf indication contraire dans la proposition)");
    } else {
        inclusions.push(t("quotation.mentioned_services"));
        inclusions.push(t("quotation.mentioned_activities"));
        inclusions.push(t("quotation.road_book"));
        inclusions.push(t("quotation.support"));

        non_inclusions.push(t("quotation.services_not_mentioned"));
        non_inclusions.push(t("quotation.check_in_luggages"));
        non_inclusions.push(t("quotation.meals_not_mentioned"));
        non_inclusions.push(t("quotation.entrance_fees"));
        non_inclusions.push(t("quotation.personal_expenses"));
        non_inclusions.push(t("quotation.esta_ave"));
        non_inclusions.push(t("quotation.travel_insurance"));

        if (accommodations.length !== 0) {
            inclusions.push(t("quotation.mentioned_accommodation"));
            inclusions.push(t("quotation.mentioned_Breakfast"));
            non_inclusions.push(t("quotation.resort_fee_not_mentioned"));
        } else {
            non_inclusions.push(t("quotation.accommodations"));
        }

        if (flights.length !== 0) {
            inclusions.push(t("quotation.mentioned_flights"));
            inclusions.push(t("quotation.airport_taxes"));
        } else {
            non_inclusions.push(t("quotation.flights"));
            non_inclusions.push(t("quotation.airport_taxes"));
        }

        if (cars.length !== 0) {
            inclusions.push(t("quotation.fully_car"));
            non_inclusions.push(t("quotation.abandonment_costs"));
            non_inclusions.push(t("quotation.gps"));
            non_inclusions.push(t("quotation.parking_gas"));
        } else {
            non_inclusions.push(t("quotation.rental_car"));
        }
    }
    // inclusions.push("Les prestations mentionnées au devis ci-dessus");
    return { inclusions: inclusions, non_inclusions: non_inclusions, flight_conditions: flight_conditions, terrestrial_conditions: terrestrial_conditions}
}